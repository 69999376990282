import React from 'react';
import { MdCloudDownload } from 'react-icons/md';
import { emitTrackEvent } from '../utils/tracking';

const DownloadButton = ({ imageUrl }) => {

  const eventKey = 'beta_create_download_image';
  const handleDownload = (imageUrl) => {
      const fileName = 'ad-image.png';
      fetch(imageUrl)
      .then(response => response.blob())
      .then(blob => {
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = blobUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(blobUrl);

        const currentImageData = JSON.parse(sessionStorage.getItem('currentImageData'));
        emitTrackEvent(eventKey, currentImageData);
      })
      .catch(error => console.error('Failed to download image', error));
    };
    return (
        <>
        <button
          className="w-auto px-5 bg-blue-500 text-white py-2 rounded-md flex items-center mx-4 hover:bg-blue-600"
          onClick={(e) => handleDownload(imageUrl)}
        >
          <MdCloudDownload className="mr-3 text-2xl" />
          Download&nbsp;Image
        </button>
        </>
    );
};

export default DownloadButton;