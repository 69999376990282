import React from 'react';
import { ReactTyped as Typed } from 'react-typed';

const TypewriterEffect = ({ className }) => {
  const words = [
    "Instagram Ads",
    "TikTok Reels",
    "Facebook Ads",
    "Google Ads",
    "LinkedIn Ads",
    "Pinterest Pins",
    "Twitter Ads",
    "YouTube Shorts"
  ];

  return (
    <h1 className={`${className} text-4xl lg:text-6xl xl:text-8xl font-bold text-green-300 mb-6 max-w-[90%] 2xl:max-w-[70%] leading-loose`}>
      Generate high converting AI-optimized images for your{' '}
      <Typed
        strings={words}
        typeSpeed={40}
        backSpeed={30}
        backDelay={2400}
        loop
        className="text-green-500"
      />
    </h1>
  );
};

export default TypewriterEffect;