import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate, Outlet } from 'react-router-dom';
import HomePage from './pages/HomePage';
import Create from './pages/Create';
import './App.css';

//GLOBALS
window.API_URL = (process.env.REACT_APP_ENV === 'dev') ? process.env.REACT_APP_DEV_API_URL : process.env.REACT_APP_PROD_API_URL;

const ApplyBodyId = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/') {
      document.body.id = 'home-page';
    } else if (location.pathname === '/create') {
      document.body.id = 'create-page';
    }

    return () => {
      document.body.id = '';
    };
  }, [location]);

  return null;
};

function App() {
  const [loggedIn, setLoggedIn] = useState(false);

  const ProtectedRoutes = () => {
    return (
        loggedIn ? <Outlet /> : <Navigate to='/'/>
      )
  }

  return (
    <Router>
      <ApplyBodyId />
      <Routes>
        <Route element={<ProtectedRoutes/>}>
          <Route path="/create" element={<Create />} />
        </Route>
        <Route path="/" element={<HomePage setLoggedIn={setLoggedIn} />} />
      </Routes>
    </Router>
  );
}

export default App;
