import React from 'react';
import { FaThumbsUp, FaThumbsDown } from 'react-icons/fa';

const ThumbsComponent = () => {
    const handleThumbsUp = () => {
        console.log('Thumbs Up Jon!');
    };

    const handleThumbsDown = () => {
        console.log('Thumbs Down Jon!');
    };

    return (
        <div className="flex items-center space-x-4 w-full relative">
            <div className='flex items-center absolute right-1 top-1 bg-white rounded-lg shadow-lg p-1'>

                <button
                    onClick={handleThumbsUp}
                    className="flex items-center text-gray-500 hover:text-green-500 focus:outline-none mr-2 p-2 pr-1"
                >
                    <FaThumbsUp size={20} />
                </button>
                <button
                    onClick={handleThumbsDown}
                    className="flex items-center text-gray-500 hover:text-red-600 focus:outline-none p-2 pl-1"
                >
                    <FaThumbsDown size={20} />
                </button>
            </div>

        </div>
    );
};

export default ThumbsComponent;