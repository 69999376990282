import React, { useState, useEffect, useRef } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import './HomePage.css';
import CustomButton from '../components/CustomButton';
import TypewriterEffect from '../components/TypewriterEffect';
import Header from '../components/Header';
import { FaArrowRight, FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';


export default function HomePage({ setLoggedIn }) {
  const navigate = useNavigate();
  const [isVideoPlayed, setIsVideoPlayed] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [isFadedIn, setIsFadedIn] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showImage, setShowImage] = useState(false);
  const videoRef = useRef(null);
  const videoRef2 = useRef(null);
  const [isVideoInView, setIsVideoInView] = useState(false);
  const videoElementRef = useRef(null);
  const sectionRef = useRef(null);
  const [isSectionInView, setIsSectionInView] = useState(false);

  

const [openFAQ, setOpenFAQ] = useState(null);

  const videos = ["../videos/makeup.mp4", "../videos/fitness.mp4"];
  const images = ["../images/makeup.png", "../images/fitness.png"];

  const handleSuccess = async (response) => {
    const authenticationResponse = await fetch(`${window.API_URL}/authenticate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(response),
    });

    const res = await authenticationResponse.json();
    console.log(">>> res from server", res);
    if (res?.authenticated) {
      const profilePic = res.currentUser.picture || "https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg";
      sessionStorage.setItem('currentUserPic', profilePic);
      sessionStorage.setItem('token', res.token);
      sessionStorage.setItem('currentUser', JSON.stringify(res.currentUser));
      setLoggedIn(true);
      navigate("/create");
    }
  };

  const handleError = (response) => {
    console.log(">>> error", response);
  };

  // Observer para los fade-in de los inner elements
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsSectionInView(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) observer.unobserve(sectionRef.current);
    };
  }, []);

  // Observer for the video (control del playback)
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVideoInView(entry.isIntersecting);
      },
      { threshold: 0.5 }
    );

    if (videoRef2.current) {
      observer.observe(videoRef2.current);
    }

    return () => {
      if (videoRef2.current) observer.unobserve(videoRef2.current);
    };
  }, []);

  const handleVideoEnd = () => {

    setTimeout(() => {
      setShowImage(true);


      setTimeout(() => {
        setIsVisible(false); 


        setTimeout(() => {
          setShowImage(false);

          // Wait for fade-out of the image to complete, then switch to the next video/image
          setTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % videos.length); 
            setIsVisible(true); // Fade-in the new video
            setIsVideoPlayed(false);
          }, 500); // Delay before switching to the next video
        }, 2500); // Show image for 2.5 seconds
      }, 350); // 350ms delay after the image appears
    }, 1000); // 1 second delay before showing the image
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true); 
        } else {
          setIsVisible(false);
        }
      },
      { threshold: 0.5 }
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) observer.unobserve(videoRef.current);
    };
  }, []);

  useEffect(() => {
    setIsFadedIn(false);
    const fadeTimeout = setTimeout(() => {
      setIsFadedIn(true);
    }, 100);

    return () => clearTimeout(fadeTimeout);
  }, []);

  // Control video playback based on visibility
  useEffect(() => {
    if (videoElementRef.current) {
      if (isVideoInView) {
        videoElementRef.current.currentTime = 0; // Reset to start
        videoElementRef.current.play();          // Play the video
      } else {
        videoElementRef.current.pause();         // Pause the video
      }
    }
  }, [isVideoInView]);

  // Tilt Effect for the Video Container
  useEffect(() => {
    const el = videoRef2.current;

    if (!el) return;

    // Get the height and width of the element
    const height = el.clientHeight;
    const width = el.clientWidth;

    // Define event handlers
    const handleMove = (e) => {
      const rect = el.getBoundingClientRect();
      const xVal = e.clientX - rect.left; // X position within the element
      const yVal = e.clientY - rect.top;  // Y position within the element

      const yRotation = 8 * ((xVal - width / 2) / width);
      const xRotation = -8 * ((yVal - height / 2) / height);

      const string = `perspective(1000px) scale(1.1) rotateX(${xRotation}deg) rotateY(${yRotation}deg)`;

      el.style.transform = string;
    };

    const handleOut = () => {
      el.style.transform = 'perspective(500px) scale(1) rotateX(0) rotateY(0)';
    };

    const handleDown = () => {
      el.style.transform = 'perspective(500px) scale(0.9) rotateX(0) rotateY(0)';
    };

    const handleUp = () => {
      el.style.transform = 'perspective(500px) scale(1.1) rotateX(0) rotateY(0)';
    };

    // Add event listeners
    el.addEventListener('mousemove', handleMove);
    el.addEventListener('mouseout', handleOut);
    el.addEventListener('mousedown', handleDown);
    el.addEventListener('mouseup', handleUp);

    // Cleanup event listeners on unmount
    return () => {
      el.removeEventListener('mousemove', handleMove);
      el.removeEventListener('mouseout', handleOut);
      el.removeEventListener('mousedown', handleDown);
      el.removeEventListener('mouseup', handleUp);
    };
  }, []);

  return (
    <>
      <div className="min-h-screen bg-gray-950 flex flex-col">
        <Header />
        <main className="flex-1 flex flex-col items-center justify-center px-6 mt-[230px]">
          <TypewriterEffect
            className={`transform transition-all duration-1000 ${
              isFadedIn ? "opacity-100 translate-y-0" : "opacity-0 translate-y-10"
            }`}
          />
          <p
            className={`transform transition-all duration-1000 text-2xl text-gray-400 delay-500 mb-8 ${
              isFadedIn ? "opacity-100 translate-y-0" : "opacity-0 translate-y-10"
            }`}
          >
            Boost Social Media Engagement with <b className="font-bold text-white">AI Images</b> Optimized for <b className="font-bold text-white">Marketing Success</b>
          </p>

          <div className={`transform transition-all duration-1000 delay-1000 text-center ${
            isFadedIn ? "opacity-100 translate-y-0" : "opacity-0 translate-y-10"
          }`}>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <CustomButton className="text-md py-2 lg:py-1.5 inline">Try Free Now <FaArrowRight className="ml-1" /></CustomButton>
              <div>
                <GoogleLogin
                  onSuccess={credentialResponse => {
                    handleSuccess(credentialResponse);
                  }}
                  onError={() => {
                    handleError('Login Failed');
                  }}
                  useOneTap
                />
              </div>
            </div>
            <p className="mt-1 text-gray-400">No credit card required</p>
          </div>
        </main>

        <section
          className={`transform transition-all duration-1000 delay-[1500ms] mt-[40px] 2xl:max-w-[1800px] mx-auto ${
            isFadedIn ? "opacity-100 translate-y-0" : "opacity-0 translate-y-10"
          }`}
        >
          <div className='relative md:w-[95%] xl:w-[90%] 2xl:w-[78%] mx-auto hover:scale-105 transition-all duration-700'>
            {/* Video Overlay */}
            <div
              ref={videoRef}
              className={`absolute z-20 md:-right-[1%] xl:-right-[3%] 2xl:right-[35%] md:top-24 xl:top-5 2xl:-top-3 shadow-lg shadow-black w-48 h-full md:w-64 md:h-64 lg:w-72 lg:h-auto transition-opacity duration-400 ${
                isVisible ? 'opacity-100' : 'opacity-0'
              }`}
            >
              <video
                src={videos[currentIndex]}
                className="w-full h-full object-cover rounded-md shadow-md"
                autoPlay
                muted
                playsInline
                onLoadedMetadata={(e) => (e.currentTarget.playbackRate = 4)}
                onEnded={() => {
                  setIsVideoPlayed(true);
                  handleVideoEnd();
                }}
              />
            </div>

            <div className="relative mx-auto w-full border border-gray-400 overflow-hidden mt-10 rounded-md bg-gray-900">
              {/* Background Image */}
              <div
                className={`absolute right-0 top-1/2 transform -translate-y-1/2 transition-transform duration-500 ease-in-out ${
                  showImage ? 'xl:translate-x-[10%] 2xl:translate-x-[0%]' : 'translate-x-[90%]'
                }`}
              >
                <img
                  src={images[currentIndex]}
                  alt="Background"
                  className="w-[80%] rotate-6 float-right -mr-16"
                />
              </div>

              <div className="relative z-10 p-10 text-white md:max-w-[42%] xl:max-w-[48%] 2xl:max-w-[40%]">
                <h2 className='text-3xl font-bold'>One Click to High-Impact Ads</h2>
                <h3 className='text-2xl'>Just Set Your Goal and Go</h3>
                <p className='text-lg my-4'>
                  Simply set your campaign goal, and our AI takes care of the rest. Generate powerful ad visuals like banners, product shots, and promotional graphics—all in seconds! With our AI trained in top social media marketing strategies, your images are crafted to boost ROI and capture your audience’s attention, putting you ahead of the competition.
                </p>
                <ol className='text-lg list-disc ml-5 space-y-4'>
                  <li>Social Media Optimized: Tailored for Instagram, LinkedIn, Facebook, TikTok, and more.</li>
                  <li>Fully Customizable: Align visuals with your brand identity and campaign goals.</li>
                </ol>
              </div>
            </div>
          </div>
        </section>


<section className="w-full flex flex-col items-center justify-center">
  <div className="max-w-6xl mx-auto px-4">
    <div className="grid grid-cols-1 md:grid-cols-2 gap-0">
      {/* Left Column */}
      <div className="border-r border-gray-400 pr-8 text-white pb-32 pt-44">
        <h2 className="text-4xl font-bold mb-4">
        Don’t Fall Behind: The Future of Advertising is AI-Driven
        </h2>
        <p className="text-xl mb-4">
        Today’s top marketers rely on AI to create high-impact visuals that captivate and convert. With just one click, our AI delivers fresh, platform-ready ads that boost engagement and performance, keeping your campaigns ahead of the competition.
        </p>
      </div>
      {/* Right Column */}
      <div className="flex flex-col justify-between text-white pb-32 pt-32">
        {/* First Row */}
        <div className="space-y-2 border-b border-gray-400 pb-14 pl-10">
          <h4 className="text-2xl font-semibold">Forbes</h4>
          <blockquote className="text-xl text-gray-300">
          <span className="text-pink-400 text-4xl absolute">“</span><span className='pl-4'>Companies using AI for image creation have seen a 20% increase in ad performance.</span>
          </blockquote>
          <p>
            <a
              href="https://www.forbes.com/sites/forbestechcouncil/2021/06/30/the-future-of-ai-in-digital-marketing/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-pink-500 font-bold hover:underline flex items-center"
            >
              Read Article <FaArrowRight className="ml-1" />
            </a>
          </p>
        </div>
        {/* Second Row */}
        <div className="pt-8 space-y-2 pl-10">
          <h4 className='text-2xl font-semibold mt-12'>Marketing Tech News</h4>
          <blockquote className="text-xl text-gray-300">
          <span className="text-pink-400 text-4xl absolute">“</span><span className='pl-4'>CAI-generated visuals are revolutionizing advertising efficiency.</span>
          </blockquote>
          <p>
            <a
              href="https://martechseries.com/predictive-ai/ai-platforms-machine-learning/ai-image-generation-transforming-digital-marketing/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-pink-500 font-bold hover:underline flex items-center"
            >
              Read Report <FaArrowRight className="ml-1" />
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</section>


        <section
          ref={sectionRef}
          className="w-full bg-pink-400 text-black flex flex-col items-center justify-center pt-20 overflow-hidden"
        >
          <h2
            className={`text-7xl font-bold mb-4 transition-all duration-1000 delay-500 leading-tight text-white ${
              isSectionInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
            }`}
          >
            See Your Ads on Every Platform
          </h2>
          <div
            className={`text-2xl transition-all duration-1000 delay-700 w-[80%] max-w-[70%] text-center ${
              isSectionInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
            }`}
          >
            <p>Preview how your ads appear on TikTok, Instagram, and other platforms. Easily customize and download your images using our simulator.</p>

              <CustomButton className="text-lg p-3 mx-auto mt-10 bg-black border-none hover:bg-white hover:text-black inline">Start Free Now<FaArrowRight className="ml-1" /></CustomButton>
          </div>

          

          <div
    className={`w-full max-w-4xl px-4 transition-all duration-1000 delay-900 mt-10 ${
      isSectionInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
    }`}
  >
          <div
            ref={videoRef2}
            className={`w-full max-w-4xl px-4 transition-all duration-1000 delay-900`}
            style={{ transition: 'box-shadow 0.1s, transform 0.1s' }}
          >
            <video
              ref={videoElementRef}
              src="../videos/iforads.mp4" 
              className="w-full h-auto rounded-tl-lg rounded-tr-lg shadow-md"
              muted
              loop
              playsInline
            />
          </div>
          </div>
        </section>







{/* Frequently Asked Questions Section */}
<section className="bg-gray-100 py-16">
  <div className="max-w-6xl mx-auto px-4">
    <h2 className="text-4xl font-bold text-center">Frequently Asked Questions</h2>
    <p className='text-center mb-12 text-2xl mt-4'>You've got more questions? <a href="#!" className='text-pink-600 underline'>Drop us a line</a> and we'll get back to you.</p>
    <div className="space-y-4">

      <div className="border border-gray-200 rounded-md hover:border-black hover:shadow-lg hover:bg-white transition-all text-gray-700 hover:text-black">
        <button
          type="button"
          className="w-full px-4 py-5 text-left text-xl flex justify-between items-center focus:outline-none "
          onClick={() => setOpenFAQ(openFAQ === 1 ? null : 1)}
          aria-expanded={openFAQ === 1}
          aria-controls="faq-1"
        >
          <span className='font-semibold'>Are the images generated royalty-free?</span>
          <svg
            className={`w-6 h-6 transform transition-transform duration-200 ${openFAQ === 1 ? 'rotate-180' : ''}`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
          </svg>
        </button>
        <div
          id="faq-1"
          className={`text-gray-600 overflow-hidden transition-all duration-300 ease-in-out px-4 xl:max-w-[70%] ${
            openFAQ === 1 ? 'max-h-96 pb-5 opacity-100' : 'max-h-0 opacity-0'
          }`}
        >
          <p>Absolutely! All the images our AI creates are completely royalty-free, so you can use them in your projects without any extra costs or licensing fees. Perfect for enhancing your marketing materials effortlessly!</p>
        </div>
      </div>


      <div className="border border-gray-200 rounded-md hover:border-black hover:shadow-lg hover:bg-white transition-all text-gray-700 hover:text-black">
        <button
          type="button"
          className="w-full px-4 py-5 text-left text-xl flex justify-between items-center focus:outline-none "
          onClick={() => setOpenFAQ(openFAQ === 2 ? null : 2)}
          aria-expanded={openFAQ === 2}
          aria-controls="faq-2"
        >
          <span className='font-semibold'>What licensing options do I have?</span>
          <svg
            className={`w-6 h-6 transform transition-transform duration-200 ${openFAQ === 2 ? 'rotate-180' : ''}`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
          </svg>
        </button>
        <div
          id="faq-2"
          className={`text-gray-600 overflow-hidden transition-all duration-300 ease-in-out px-4 xl:max-w-[70%] ${
            openFAQ === 2 ? 'max-h-96 pb-5 opacity-100' : 'max-h-0 opacity-0'
          }`}
        >
          <p>You have full commercial rights to use the images in your marketing, advertising, and social media campaigns. Whether you’re a small startup or a large enterprise, our licensing options are designed to fit your needs seamlessly.</p>
        </div>
      </div>


      <div className="border border-gray-200 rounded-md hover:border-black hover:shadow-lg hover:bg-white transition-all text-gray-700 hover:text-black">
        <button
          type="button"
          className="w-full px-4 py-5 text-left text-xl flex justify-between items-center focus:outline-none "
          onClick={() => setOpenFAQ(openFAQ === 3 ? null : 3)}
          aria-expanded={openFAQ === 3}
          aria-controls="faq-3"
        >
          <span className='font-semibold'>How do you handle copyright issues?</span>
          <svg
            className={`w-6 h-6 transform transition-transform duration-200 ${openFAQ === 3 ? 'rotate-180' : ''}`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
          </svg>
        </button>
        <div
          id="faq-3"
          className={`text-gray-600 overflow-hidden transition-all duration-300 ease-in-out px-4 xl:max-w-[70%]  ${
            openFAQ === 3 ? 'max-h-96 pb-5 opacity-100' : 'max-h-0 opacity-0'
          }`}
        >
          <p>We take copyright seriously! Our AI generates unique images to prevent any infringements, ensuring that your ads are both creative and compliant. If you ever have concerns, our support team is here to help.</p>
        </div>
      </div>

      <div className="border border-gray-200 rounded-md hover:border-black hover:shadow-lg hover:bg-white transition-all text-gray-700 hover:text-black">
        <button
          type="button"
          className="w-full px-4 py-5 text-left text-xl flex justify-between items-center focus:outline-none "
          onClick={() => setOpenFAQ(openFAQ === 4 ? null : 4)}
          aria-expanded={openFAQ === 4}
          aria-controls="faq-4"
        >
          <span className='font-semibold'>Where can I use my images?</span>
          <svg
            className={`w-6 h-6 transform transition-transform duration-200 ${openFAQ === 4 ? 'rotate-180' : ''}`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
          </svg>
        </button>
        <div
          id="faq-4"
          className={`text-gray-600 overflow-hidden transition-all duration-300 ease-in-out px-4 xl:max-w-[70%] ${
            openFAQ === 4 ? 'max-h-96 pb-5 opacity-100' : 'max-h-0 opacity-0'
          }`}
        >
          <p>You can use your images across various platforms, including websites, social media, print materials, and more! Our founding team has hands-on experience working with big tech companies like LinkedIn and Facebook. This insider knowledge of their algorithms allows us to develop an industry-approved simulator, ensuring your campaigns run smoothly without the worry of copyright strikes. We’re dedicated to supporting your success with expertise you can trust!
          </p>
        </div>
      </div>


      <div className="border border-gray-200 rounded-md hover:border-black hover:shadow-lg hover:bg-white transition-all text-gray-700 hover:text-black">
        <button
          type="button"
          className="w-full px-4 py-5 text-left text-xl flex justify-between items-center focus:outline-none "
          onClick={() => setOpenFAQ(openFAQ === 5 ? null : 5)}
          aria-expanded={openFAQ === 5}
          aria-controls="faq-5"
        >
          <span className='font-semibold'>Can I use the service for free?</span>
          <svg
            className={`w-6 h-6 transform transition-transform duration-200 ${openFAQ === 5 ? 'rotate-180' : ''}`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
          </svg>
        </button>
        <div
          id="faq-5"
          className={`text-gray-600 overflow-hidden transition-all duration-300 ease-in-out px-4 xl:max-w-[70%] ${
            openFAQ === 5 ? 'max-h-96 pb-5 opacity-100' : 'max-h-0 opacity-0'
          }`}
        >
          <p>Absolutely! We offer a free tier that lets you get started right away. Plus, our paid plans with more advanced tools are coming soon. Join the free tier now and enjoy a special discount on our upcoming plans as a thank you for being an early adopter!
          </p>
        </div>
      </div>
    </div>
  </div>
</section>




        <footer className="bg-gray-900 py-6">
          <div className="container mx-auto px-4">
            <div className="flex flex-col md:flex-row justify-between items-center">
              
              <p className="text-gray-200">
                ImagesForAds. &copy; {new Date().getFullYear()} LaReverie, LLC. All rights reserved.
              </p>

              
              <div className="mt-4 md:mt-0 flex flex-col md:flex-row items-center">
              
                <div className="flex space-x-4">
                  <a href="#!" className="text-gray-200 hover:text-gray-400 transition-colors duration-200">Sitemap</a>
                  <a href="#!" className="text-gray-200 hover:text-gray-400 transition-colors duration-200">Privacy Policy</a>
                  <a href="#!" className="text-gray-200 hover:text-gray-400 transition-colors duration-200">Terms of Service</a>
                </div>

              {/* ICONOS social medixa */}
                <div className="flex space-x-4 mt-4 md:mt-0 md:ml-6">
                  <a href="#!" className="text-gray-200 hover:text-gray-400 transition-colors duration-200">
                    <FaFacebookF size={20} />
                  </a>
                  <a href="#!" className="text-gray-200 hover:text-gray-400 transition-colors duration-200">
                    <FaTwitter size={20} />
                  </a>
                  <a href="#!" className="text-gray-200 hover:text-gray-400 transition-colors duration-200">
                    <FaInstagram size={20} />
                  </a>
                  <a href="#!" className="text-gray-200 hover:text-gray-400 transition-colors duration-200">
                    <FaLinkedinIn size={20} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}