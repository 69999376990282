import React, { useEffect, useState } from 'react';
import './BetaBanner.css';

const BetaBanner = ({ handleFeedbackClick }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [isSubscribed, setIsSubscribed] = useState(false);

    const handleSubscribe = async () => {
      try {
          const subResponse = await fetch(`${window.API_URL}/subscribe`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                  currentUser: sessionStorage.getItem('currentUser'),
                  broadcastId: '67009bb6561716ca36c62d69',
              }),
          });

          if (subResponse.status !== 200) {
              // TODO show error message
              alert('An error occurred while subscribing to notifications');
              return;
          }
          setIsSubscribed(true);
          return;
      } catch (error) {
        // TODO show error message
        alert('An error occurred while subscribing to notifications');
        return;
      }
    };

    const handleClose = () => {
        setIsVisible(false);
      };
    

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 500); 

        return () => clearTimeout(timer);
    }, []);

    return (
        <div
      className={`fixed bottom-0 left-0 right-0 md:top-0 md:relative md:m-0 md:rounded-none bg-gray-800 transition-all duration-700 ease-in-out z-50 m-2 beta-banner ${
        isVisible ? 'opacity-100 md:mt-0' : 'opacity-0 overflow-hidden md:-mt-[50px]'
      } rounded-md`}
    >
      <div className="flex flex-col md:flex-row items-center justify-center py-2 md:py-3 px-4 h-full relative">
        <p className="text-white md:ml-2 md:mt-0 flex items-center h-full mr-2 mb-1 md:mb-0">
          We are currently in BETA!
        </p>
        <button
          onClick={handleFeedbackClick}
          className="cta bg-gradient-to-r from-pink-500 to-purple-600 text-white rounded-md px-3 py-1.5 md:px-2 md:py-1 hover:from-pink-700 hover:to-purple-800 mb-2 md:mb-0 text-lg md:text-sm flex items-center h-full pl-2 pr-2"
        >
          Share your feedback
        </button>
        {isSubscribed ? (
          <p className="text-white md:ml-2 md:mt-0 flex items-center h-full">
            You are now subscribed to receive notifications!
          </p>
        ) : (
          <p className="text-white md:ml-2 md:mt-0 flex items-center h-full">
            and&nbsp;
            <button onClick={handleSubscribe} className="cta-link">
              sign up for feature notifications!
            </button>
          </p>
        )}
        {/* Close Button */}
        <button
          onClick={handleClose}
          className="text-white text-xl ml-4 md:ml-2 focus:outline-none absolute right-4 top-1 md:hidden"
        >
          &times;
        </button>
      </div>
    </div>
    );
};

export default BetaBanner;
