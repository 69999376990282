import { useState, useEffect, useRef } from 'react';
import FactCarousel from '../components/Facts';
import PostMockup from '../components/PostMockup';
import AdSizeSelector from '../components/AdSizeSelector';
import UserMessage from '../components/UserMessage';
import Feedback from '../components/Feedback';
import AdForm from '../components/AdForm';
import DownloadButton from '../components/DownloadButton'; 
import BetaBanner from '../components/BetaBanner';
import './Create.css';
import ThumbsComponent from '../components/ThumbsComponent';
import EditButton from '../components/EditButton';

const logo = require('../images/logo-02.png');
const facts = [
    "AI is transforming how we target ads with personalization.",
    "Programmatic advertising is driven by AI to optimize in real-time.",
    "By 2025, AI is projected to manage 80% of digital ads.",
    "AI analyzes user data faster than any human ever could.",
    "AI-driven marketing campaigns have increased ROI for brands by over 30%."
];


export default function App() {
    const [step, setStep] = useState(1);
    const [subject, setSubject] = useState('');
    const [url, setUrl] = useState('');
    const [objective, setObjective] = useState('');
    const [adCopy, setAdCopy] = useState('');
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [isReady, setIsReady] = useState(false);
    const [adSize, setAdSize] = useState('aspect-square max-w-full');
    const [platform, setPlatform] = useState('facebook');
    const [userMessage, setUserMessage] = useState('');
    const [messageType, setMessageType] = useState('info');
    const feedbackRef = useRef();


    const handleAdSizeChange = (newAdSize, platform) => {
        setAdSize(newAdSize);
        setPlatform(platform);
    };

    const handleFeedbackClick = () => {
        feedbackRef.current.handleFeedbackClick();
    };

    useEffect(() => {
        const updateLayout = () => {
            const sidebar = document.querySelector('.sidebar-class');
            const content = document.querySelector('.content-class');
            if (sidebar && content) {
                const newHeight = Math.max(content.scrollHeight, window.innerHeight);
                sidebar.style.height = `${newHeight}px`; // Dynamically set the height of the sidebar
            }
        };

        updateLayout();

        const observer = new MutationObserver(updateLayout);
        const config = { childList: true, subtree: true };

        const content = document.querySelector('.content-class');
        if (content) observer.observe(content, config);

        return () => observer.disconnect();
    }, [adSize, isReady]);



    // UseEffect para verificar los elementos al cargar la página
    useEffect(() => {
        // Solo verificar si la resolución es de desktop/tablet
        const isDesktopOrTablet = window.innerWidth >= 768;

        if (isDesktopOrTablet) {
            // Verificar si los elementos están en el DOM
            const formElements = ['subject', 'url', 'objective', 'adCopy'];
            const allFieldsExist = formElements.every(id => document.querySelector(`[name="${id}"]`));

            if (!allFieldsExist) {
                console.warn('Some form fields are missing. This may be caused by an ad blocker.');
                setUserMessage('Some form fields are missing. This may be caused by an ad blocker. Please disable it and reload the page.');
                setMessageType('error');
            }
        }

    }, []); // Solo ejecutar al cargar la página

    return (

        <div className='h-auto w-full overflow-hidden'>
            <UserMessage
                message={userMessage}
                type={messageType}
            />

            <BetaBanner handleFeedbackClick={handleFeedbackClick} />
            <div className="flex flex-col md:flex-row bg-gray-200 h-screen">

                {/* Left Column */}
                <div className="ad-form-wrap w-full md:w-1/3 xl:w-1/3 relative 2xl:w-1/4 px-8 md:px-[1em] xl:px-8 py-6 flex flex-col bg-white sidebar-class pb-[20px]">
                    <header className="flex flex-col items-start mb-6 space-y-4 mx-auto md:mx-0">
                        <a href="/create">
                            <img src={logo} alt="images.forads.ai" className="m-[-7px] max-w-[300px] w-full z-20 relative" />
                        </a>
                    </header>

                    <Feedback ref={feedbackRef} />

                    <AdForm
                        step={step}
                        setStep={setStep}
                        subject={subject}
                        setSubject={setSubject}
                        url={url}
                        setUrl={setUrl}
                        objective={objective}
                        setObjective={setObjective}
                        adCopy={adCopy}
                        setAdCopy={setAdCopy}
                        loading={loading}
                        facts={facts}
                        handleFeedbackClick={handleFeedbackClick}
                        setLoading={setLoading}
                        setImageUrl={setImageUrl}
                        setIsReady={setIsReady}
                        setMessageType={setMessageType}
                    />
                </div>

                {/* Generated Content */}
                <div
                    id="results"
                    className="w-full relative overflow-y-auto pb-5"
                >
                    <div className='relative float-right mr-5 mt-5'>
                        <img src={sessionStorage.getItem('currentUserPic')} alt="Profile Pic" className='w-10 md:w-14 rounded-full border-2 border-white shadow-md' />
                    </div>
                    {/* Mostrar un div si al menos una de las condiciones es verdadera (imageUrl son falsos) */}
                    {!imageUrl && (
                        <div className="flex items-center justify-center h-full">
                            {loading && (
                                <div
                                    id="generating"
                                    className={`w-full h-full absolute z-10 bg-gradient-to-r from-amber-500 to-pink-500 text-md text-white text-center left-0 top-0 flex items-center justify-center transition-opacity duration-500 ease-in-out ${loading && 'animate-fade-in'}`}
                                >
                                    <div className="animate-scale-fade-in">
                                        <img src="./spinner.svg" alt="Loading spinner" className='mx-auto mb-3' />
                                        <FactCarousel facts={facts} duration={5000} />
                                    </div>
                                </div>
                            )}

                            <div className="text-center md:px-10 lg:px-8 bg-gray-300 rounded-lg m-10 text-gray-500 flex items-center justify-center relative py-10 px-36">
                                <p>Your ad preview will show here after you generate an image</p>
                            </div>
                        </div>
                    )}

                    <div className='w-full lg:w-2/3 mx-auto'>
                        {/* Mostrar la imagen generada si está disponible */}
                        {isReady && imageUrl && (
                            <div className="section image w-full">
                                <div className="text-center mb-4 flex items-center justify-center bg-gray-300 mt-5 rounded-md p-2">
                                    <AdSizeSelector onAdSizeChange={handleAdSizeChange} />
                                    <DownloadButton imageUrl={imageUrl} />
                                    <EditButton />
                                </div>
                                <ThumbsComponent />
                                <PostMockup platform={platform} imageUrl={imageUrl} adSize={adSize} />
                            </div>
                        )}

                    </div>

                </div>

                <div id="results_btn" className='left-0 w-full h-40 createSliderBack hidden lg:hidden'>
                    <button
                        onClick={() => window.location.reload()}
                        className="w-90 absolute bottom-2 left-2 right-2 bg-pink-600 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-opacity-50 md:block"
                    >
                        + Generate a new image
                    </button>
                </div>

            </div>

        </div>
    );
}