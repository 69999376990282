const emitTrackEvent = async (eventKey, eventData) => {
  try {
    const response = await fetch(`${window.API_URL}/track`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        eventKey,
        ...eventData,
      }),
    });
  } catch (error) {
    console.error('Failed to track event', error);
  }

  window.gtag('event', eventKey, {
    'event_category': 'User Interaction',
    ...eventData
  });
};

export { emitTrackEvent };