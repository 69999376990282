// Header.jsx
import React, { useState, useRef, useEffect } from 'react';
import CustomButton from './CustomButton'; 
import logo from '../images/logo-01.png'; 

// Import React Icons for Hamburger and Close Icons
import { IoMenu, IoClose } from 'react-icons/io5'; // Ionicons (Io5)

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const menuRef = useRef();

  const handleToggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // Close the menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMobileMenuOpen(false);
      }
    };

    if (isMobileMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobileMenuOpen]);

  return (
    <header className="fixed top-0 left-1/2 transform -translate-x-1/2 w-full max-w-[90%] md:max-w-[90%] xl:max-w-[75%] bg-gray-900 mt-6 rounded shadow-md shadow-black z-50">
      <div className="flex items-center justify-between px-4 py-3">
        {/* Logo */}
        <img className="w-full max-w-[200px]" src={logo} alt="imagesMarketer logo" />

        {/* Desktop Navigation */}
        <nav className="hidden lg:flex space-x-6">
          <a href="#" className="hover:bg-gray-600 p-3 rounded text-gray-400 hover:text-white transition-all">
            Home
          </a>
          <a href="#" className="hover:bg-gray-600 p-3 rounded text-gray-400 hover:text-white transition-all">
            Features
          </a>
          <a href="#" className="hover:bg-gray-600 p-3 rounded text-gray-400 hover:text-white transition-all">
            Pricing
          </a>
          <a href="#" className="hover:bg-gray-600 p-3 rounded text-gray-400 hover:text-white transition-all">
            About
          </a>
          <a href="#" className="hover:bg-gray-600 p-3 rounded text-gray-400 hover:text-white transition-all">
            Contact
          </a>
        </nav>

        {/* Desktop "Try Free Now" Button */}
        <div className="hidden lg:block">
          <CustomButton className="text-xl py-3">Try Free Now</CustomButton>
        </div>

        {/* Mobile Menu Button */}
        <div className="lg:hidden">
          <button
            onClick={handleToggleMobileMenu}
            className="text-white focus:outline-none"
            aria-label="Toggle Menu"
            aria-expanded={isMobileMenuOpen}
            aria-controls="mobile-menu"
          >
            {isMobileMenuOpen ? (
              <IoClose className="h-6 w-6 text-2xl" /> // Close Icon
            ) : (
              <IoMenu className="h-6 w-6 text-2xl" /> // Hamburger Icon
            )}
          </button>
        </div>
      </div>

      {/* Mobile Navigation Menu */}
      <div
        ref={menuRef}
        id="mobile-menu"
        className={`lg:hidden px-6 pb-4 bg-gray-700 rounded-b-lg shadow-md shadow-gray-400 transform transition-all duration-300 ease-in-out ${
          isMobileMenuOpen ? 'max-h-96 opacity-100 translate-y-0 mb-0' : 'max-h-0 opacity-0 -translate-y-2 -mb-5'
        } overflow-hidden`}
      >
        <nav className="flex flex-col space-y-1 text-center text-xl">
          <a href="#" className="hover:bg-gray-600 p-3 rounded text-white" onClick={() => setIsMobileMenuOpen(false)}>
            Home
          </a>
          <a href="#" className="hover:bg-gray-600 p-3 rounded text-white" onClick={() => setIsMobileMenuOpen(false)}>
            Features
          </a>
          <a href="#" className="hover:bg-gray-600 p-3 rounded text-white" onClick={() => setIsMobileMenuOpen(false)}>
            Pricing
          </a>
          <a href="#" className="hover:bg-gray-600 p-3 rounded text-white" onClick={() => setIsMobileMenuOpen(false)}>
            About
          </a>
          <a href="#" className="hover:bg-gray-600 p-3 rounded text-white" onClick={() => setIsMobileMenuOpen(false)}>
            Contact
          </a>
        </nav>
        <div className="mt-4">
          <CustomButton className="w-full text-xl py-3">Try Free Now</CustomButton>
        </div>
      </div>
    </header>
  );
};

export default Header;