import React, { useImperativeHandle, forwardRef, useState } from 'react';
import { IoArrowBackSharp } from "react-icons/io5";
import { MdOutlineFeedback } from "react-icons/md";
import { FcInfo } from "react-icons/fc";


const Feedback = forwardRef((props, ref) => {
    const [showFeedback, setShowFeedback] = useState(false);
    const [feedback, setFeedback] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    useImperativeHandle(ref, () => ({
        handleFeedbackClick: () => {
            setShowFeedback(true);
            showFlasher();
        },
    }));

    const hideFlasher = () => {
      const blinkingArrow = document.getElementById('blinking-arrow');
      if (blinkingArrow) {
        blinkingArrow.style.display = 'none';
      }
    };

    const showFlasher = () => {
      const blinkingArrow = document.getElementById('blinking-arrow');
      if (blinkingArrow) {
        blinkingArrow.style.display = 'block';
      }
    };

    const handleCloseFeedback = () => {
        setShowFeedback(false);
        setMessage(''); // Clear the message when we close the form
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setMessage('');

        // Validation: Check if feedback is empty
        if (!feedback.trim()) {
            setMessage('Please enter your feedback!'); 
            setLoading(false); 
            return; 
        }

        try {
            const response = await fetch('https://api.emailjs.com/api/v1.0/email/send', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    service_id: 'service_pjt2mw5',
                    template_id: 'template_mreqknf',
                    user_id: '2r6QUHl2d4wYjJI5a', // In settings in EmailJS this is the "API Public Key"
                    template_params: {
                        message: feedback,
                    },
                }),
            });

            if (response.ok) {
                setMessage('Feedback sent, thank you!'); 
                setFeedback(''); 
            } else {
                setMessage('Oops! Please try again.'); 
            }
        } catch (error) {
            setMessage('Oops! Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <div 
                id="feedback" 
                className={`left-0 top-0 h-full w-full absolute bg-pink-100 shadow-lg pt-20 px-3 transition-transform duration-300 ease-in-out ${showFeedback ? 'translate-x-0' : '-translate-x-full'}`}
                onClick={hideFlasher}
            >
                <div className="p-1">
                    <button 
                        onClick={handleCloseFeedback} 
                        className="flex items-center text-gray-500 hover:text-pink-600"
                    >
                        <IoArrowBackSharp className="mr-2" /> 
                        Go Back
                    </button>
                    <div className='flex mt-4 mb-4 items-center'>
                        <MdOutlineFeedback className='text-3xl mr-2'/>
                        <h2 className="text-xl font-semibold">What do you think?</h2>
                    </div>
                    <p className='mb-3 text-gray-500'>
                      Your experience helps us improve! Please share your thoughts below.
                      <br /><br />
                      Interested in joining our Ad Partner Beta Program? Awesome! Let us know and please include your email address in your feedback.
                    </p>
                    <div className="blinking-arrow" id="blinking-arrow">&#x2193;</div>
                    <form onSubmit={handleSubmit}>
                        <textarea 
                            className="w-full h-32 p-3 border rounded" 
                            placeholder="Your feedback here..."
                            value={feedback}
                            onChange={(e) => setFeedback(e.target.value)}
                            disabled={loading}
                        ></textarea>
                        <button 
                            type="submit" 
                            className="mt-1 px-4 py-2 bg-purple-600 hover:bg-pink-500 text-white rounded w-full"
                            disabled={loading}
                        >
                            {loading ? 'Sending...' : 'Send'}
                        </button>
                    </form>
                    {message && (
                        <p className="mt-2 text-center text-blue-800 flex items-center justify-center">
                            <FcInfo className="mr-1" />
                            {message}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
});

export default Feedback;