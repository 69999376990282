import React from 'react';

const UserMessage = ({ message, type }) => {
    // Map message types to styles
    const messageStyles = {
        error: 'bg-red-100 text-red-700',
        warning: 'bg-yellow-100 text-yellow-700',
        info: 'bg-blue-100 text-blue-700',
        success: 'bg-green-100 text-green-700',
    };

    // Use default style if type is not provided
    const style = messageStyles[type] || messageStyles.info;

    // Solo mostrar si hay un mensaje
    if (!message) return null;

    return (
        <div className={`p-4 rounded-md w-full  top-0 text-center ${style}`} id="UserMessage">
            {message}
        </div>
    );
};

export default UserMessage;