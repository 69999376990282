import { useState, useEffect } from 'react';
import FactCarousel from '../components/Facts'; 

const AdForm = ({
    step,
    setStep,
    subject,
    setSubject,
    url,
    setUrl,
    objective,
    setObjective,
    adCopy,
    setAdCopy,
    loading,
    facts,
    handleFeedbackClick,
    setLoading,
    setImageUrl,
    setIsReady,
    setMessageType
}) => {
    const [fadeIn, setFadeIn] = useState(true);

    const handleNext = (event) => {
        event.preventDefault();
        if (step < 4) {
            setFadeIn(false);
            setTimeout(() => {
                setStep(step + 1);
            }, 400);
        } else {
            handleSubmit(event);
        }
    };

    const handleBack = (event) => {
        event.preventDefault();
        if (step > 1) {
            setFadeIn(false);
            setTimeout(() => {
                setStep(step - 1);
            }, 400);
        }
    };

    useEffect(() => {
        setFadeIn(false);
        const timer = setTimeout(() => {
            setFadeIn(true);
        }, 300);
        return () => clearTimeout(timer);
    }, [step]);

    // Submit form
    const handleSubmit = async (e, retryCount = 0) => {
        e.preventDefault();
        setLoading(true);
        setImageUrl('');
        setIsReady(false);

        const userInput = { subject, url, objective, ad_copy: adCopy };
        const maxRetries = 3; // Maximum number of retries

        try {
            const response = await fetch(`${window.API_URL}/generate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userInput,
                    currentUser: sessionStorage.getItem('currentUser')
                }),
            });
            const responseData = await response.json();
            const img = new Image();
            img.src = responseData.imageUrl;

            sessionStorage.setItem('currentImageData', JSON.stringify(responseData));
            img.onload = () => {
                setImageUrl(responseData.imageUrl);
                setIsReady(true);
                setLoading(false);
            };
        } catch (error) {
            // throw new Error(error);
            if (retryCount < maxRetries) {
                console.log(`Retrying... (${retryCount + 1}/${maxRetries})`);
                handleSubmit(e, retryCount + 1);
            } else {
                setLoading(false);
                setMessageType('error');
            }
        }

        const resultsElement = document.getElementById('results');
        const resultsBtn = document.getElementById('results_btn');

        if (window.innerWidth < 768) {
            document.body.style.setProperty('overflow-y', 'scroll', 'important');
            resultsElement.classList.remove('hidden');
            resultsElement.classList.add('sliderUp');
            resultsBtn.classList.remove('hidden');
            resultsBtn.classList.add('sliderUp');
        }
    };

    return (
        <>
            {window.innerWidth < 768 ? (
                <div className="ad-form flex items-center justify-center min-h-screen mt-[-90px]">
                    {loading && (
                        <div
                            id="generating"
                            className={`w-screen h-screen absolute z-10 bg-gradient-to-r from-amber-500 to-pink-500 text-md text-white text-center left-0 top-0 flex items-center justify-center transition-opacity duration-500 ease-in-out`}
                        >
                            <div className="animate-scale-fade-in">
                                <img src="../spinner.svg" alt="Loading spinner" className="mx-auto mb-3" />
                                <FactCarousel facts={facts} duration={5000} />
                            </div>
                        </div>
                    )}

                    <div className={`step-form transition-opacity duration-500 ${fadeIn ? 'opacity-100' : 'opacity-0'}`}>
                        <form id="profileForm" onSubmit={handleNext} className="space-y-6">
                            {step === 1 && (
                                <div>
                                    <h2 className="text-3xl mb-3 font-semibold">Tell us what you're advertising</h2>
                                    <input
                                        type="text"
                                        placeholder="product/service/brand"
                                        value={subject}
                                        onChange={(e) => setSubject(e.target.value)}
                                        required
                                        className="w-full p-3 border border-gray-300 rounded-md"
                                    />
                                    <button
                                        type="submit"
                                        disabled={!subject.trim()}
                                        className={`w-full text-white font-bold py-4 px-4 rounded mt-4 ${!subject.trim() ? 'bg-gray-400 cursor-not-allowed' : 'bg-gradient-to-r from-cyan-500 to-blue-500'}`}
                                    >
                                        Next
                                    </button>
                                </div>
                            )}

                            {step === 2 && (
                                <div>
                                    <h2 className="text-3xl mb-3 font-semibold">Tell us about your ad campaign</h2>
                                    <input
                                        type="text"
                                        placeholder="objective"
                                        value={objective}
                                        onChange={(e) => setObjective(e.target.value)}
                                        required
                                        className="w-full p-3 border border-gray-300 rounded-md"
                                    />
                                    <input
                                        type="text"
                                        placeholder="Ad Copy"
                                        value={adCopy}
                                        onChange={(e) => setAdCopy(e.target.value)}
                                        required
                                        className="w-full p-3 border border-gray-300 rounded-md mt-4"
                                    />
                                    <button
                                        type="submit"
                                        disabled={!objective.trim() || !adCopy.trim()}
                                        className={`w-full text-white font-bold py-4 px-4 rounded ${!objective.trim() || !adCopy.trim() ? 'bg-gray-400 cursor-not-allowed' : 'bg-gradient-to-r from-cyan-500 to-blue-500'}`}
                                    >
                                        Next
                                    </button>
                                    <a
                                        href="#!"
                                        onClick={handleBack}
                                        className="w-full block text-pink-500 font-bold focus:outline-none underline mx-auto mt-4 text-center"
                                    >
                                        Go Back
                                    </a>
                                </div>
                            )}

                            {step === 3 && (
                                <div>
                                    <h2 className="text-3xl mb-3 font-semibold">Where will your ad point to?</h2>
                                    <input
                                        type="text"
                                        placeholder="URL"
                                        value={url}
                                        onChange={(e) => setUrl(e.target.value)}
                                        required
                                        className="w-full p-3 border border-gray-300 rounded-md"
                                    />
                                    <button
                                        type="submit"
                                        disabled={!url.trim()}
                                        className={`w-full text-white font-bold py-4 px-4 rounded mt-3 ${!url.trim() ? 'bg-gray-400 cursor-not-allowed' : 'bg-gradient-to-r from-cyan-500 to-blue-500'}`}
                                    >
                                        Next
                                    </button>
                                    <a
                                        href="#!"
                                        onClick={handleBack}
                                        className="w-full block text-pink-500 font-bold focus:outline-none underline mx-auto mt-4 text-center"
                                    >
                                        Go Back
                                    </a>
                                </div>
                            )}

                            {step === 4 && (
                                <div>
                                    <button
                                        type="submit"
                                        disabled={loading}
                                        className={`w-full px-7 py-4 text-2xl rounded-md hover:bg-gradient-to-r hover:from-fuchsia-700 hover:to-pink-700 ${loading ? 'cursor-not-allowed text-white bg-gradient-to-r from-stone-500 to-stone-700' : 'bg-gradient-to-r from-fuchsia-600 to-pink-600 text-white'}`}
                                    >
                                        Ignite the AI Genius
                                    </button>
                                    <a
                                        href="#!"
                                        onClick={handleBack}
                                        className="w-full block text-pink-500 font-bold focus:outline-none underline mx-auto mt-4 text-center"
                                    >
                                        Go Back
                                    </a>
                                </div>
                            )}
                        </form>
                    </div>
                </div>
            ) : (
                <form id="profileForm" onSubmit={handleSubmit} className="space-y-5">
                    <div className="section ad space-y-3">
                        <h2 className="text-lg md:text-sm lg:text-lg font-semibold mb-4">Tell us what you're advertising</h2>
                        <div className="accordion">
                            <input
                                type="text"
                                name="subject"
                                placeholder="product/service/brand"
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                                required
                                className="w-full p-3 border border-gray-300 rounded-md"
                            />
                            <p className="text-gray-500 text-sm mb-4">Name and a short description</p>
                            <input
                                type="text"
                                name="url"
                                placeholder="URL"
                                value={url}
                                onChange={(e) => setUrl(e.target.value)}
                                required
                                className="w-full p-3 border border-gray-300 rounded-md"
                            />
                            <p className="text-gray-500 text-sm">For your product or service</p>
                        </div>
                    </div>

                    <div className="section campaign space-y-3">
                        <h2 className="text-lg md:text-sm lg:text-lg font-semibold mb-4">Tell us about your ad campaign</h2>
                        <div className="accordion">
                            <input
                                type="text"
                                name="objective"
                                placeholder="objective"
                                value={objective}
                                onChange={(e) => setObjective(e.target.value)}
                                required
                                className="w-full p-3 border border-gray-300 rounded-md"
                            />
                            <p className="text-gray-500 text-sm mb-4">Main goal of your ad campaign</p>
                            <input
                                type="text"
                                name="adCopy"
                                placeholder="Ad Copy"
                                value={adCopy}
                                onChange={(e) => setAdCopy(e.target.value)}
                                required
                                className="w-full p-3 border border-gray-300 rounded-md"
                            />
                            <p className="text-gray-500 text-sm">The text for your ad</p>
                        </div>
                    </div>

                    <div className="section submit space-y-4  2xl:pt-15">
                        <button
                            type="submit"
                            disabled={loading}
                            className={`w-full py-4 text-2xl md:text-lg lg:text-2xl rounded-md ${loading ? 'cursor-not-allowed text-white bg-gradient-to-r from-stone-500 to-stone-700' : 'bg-gradient-to-r from-fuchsia-600 to-pink-600 text-white hover:bg-gradient-to-r hover:from-fuchsia-700 hover:to-pink-700'}`}
                        >
                            Ignite the AI Genius
                        </button>
                        <p className="text-center">
                            <button
                                onClick={handleFeedbackClick}
                                className="w-full block text-pink-500 font-semibold focus:outline-none underline mx-auto mt-4 text-center"
                                type="button"
                            >
                                We need your feedback!
                            </button>
                        </p>
                    </div>
                </form>
            )}
        </>
    );
};

export default AdForm;